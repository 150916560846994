import React from "react";
import Layout from "../../components/Layout";
import { getLanguage, getData } from "../../helper/helper";

const locale = getLanguage();
const contact = getData(locale).contactPage;

const Thanks = () => (
  <Layout locale={locale}>
    <section className="section">
      <div className="container">
        <div className="content">
          <h1>{contact.title}</h1>
          <p>{contact.content}</p>
        </div>
      </div>
    </section>
  </Layout>
);

export default Thanks;
